<template>
    <div v-loading="loading">
        <div>
            <el-page-header @back="goBack" content="数据统计">
            </el-page-header>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LiuLiangRecord",
        data() {
            return {
                query: {
                    pageIndex: 1,
                    pageSize: 5,
                    userId: null
                },
                loading:false
            }
        },
        methods: {
            goBack() {
                this.$router.replace('/userList')
            },
        },
        created() {
            this.query.userId = this.$route.query.userId;
        }
    }
</script>

<style scoped>

</style>
